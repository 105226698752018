
    /* .codeeditor {
      color: rgb(35, 34, 34);
      background-color: rgb(255, 255, 255);
      height: 100%;
      width: 100%;
      overflow: auto;
      padding: 10px;
      border-radius: 5px 5px 5px 5px;
      line-height: 1.8em;
      outline-width: 0;
      padding: 20px;
      
    } */

    .codeeditor {
      color: #d4d4d4; /* Text color */
      background-color: #1e1e1e; /* Background color */
      height: 100%;
      width: 100%;
      overflow: auto;
      padding: 20px;
      border-radius: 5px;
      line-height: 1.8em;
      outline-width: 0;
      font-family: 'Consolas', 'Courier New', monospace; /* Font similar to VSCode */
      font-size: 14px; /* Font size similar to VSCode */
      white-space: pre; /* Preserve whitespace */
      tab-size: 4; /* Tab size */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for depth */
    }
    
    .img-wrapper {
        /* Your existing styles */
        padding-top:  "0px";
        padding-left:  "20px";
        padding-right: "20px";
        padding-bottom: 20px;
        overflow: auto;
        height: 92.5%;
        width: 100%;
        max-width: 100%;
        background-color: rgb(255, 255, 255);
        background-image: url("../../imges/dotted_grid_image_white_02.png");
        background-size: cover;
        transition: background-image 0.3s ease-in-out;

        position: relative;
        overflow-x: auto;
        overflow-y: auto;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin: auto;
        /* border-radius: 10px; */
        /* box-shadow: 2px 2px rgb(110, 123, 123); */
      }
      
      .img-wrapper.flipped {
        background-color: rgb(0, 0, 0); /* Change background color when flipped */
        background-image: url("../../imges/dotted_grid_image_dark_02.png");
        background-size: cover;
        
        transition: background-image 0.3s ease-in-out;
        color: white; /* For better visibility if necessary */
      }
      
    .img-wrapper > img {
        position:relative;
        /* transform: translateY(-50%); */
        /* top: 10%; */
        opacity: 1.0;
        padding: 10px;

    } 
   
    .codemenubar {
        position: absolute;
        top: 0;
        right: 0;
        justify-content: right; 
    }