.work-section {
    display: flex;
    flex: 1;
    height: 1000px;
     /* background-image: url('../../imges/background02.jpg'); 
  background-size: cover; */
}
.left-sidebar { 
  
  width:20%;  
  /* background: #faf9f9;  */
  float:left; padding: 12px; min-width:min-content; max-width:min-content; padding-top:25px;}
.right-area { 
  background-image: url('../../imges/background02.jpg'); 
  background-size: cover;
  width:100%; 
  /* height:900px;  */
  /* background: #ffffff45;  */
  float:left }

.image-editor {
    background: #f4f3f3;

    /* background-image: radial-gradient(#1e1b1b 5%, transparent 19); */
    /* background-size: 35px 35px;  */
}
.left-sidebar {
  background-image: url('../../imges/background02.jpg'); 
  background-size: cover;
  background-repeat: no-repeat; 
}


/* #below { height:300px; width:100%; background:green; clear:both } */
  
  /* .left-bottom-section {
    flex: 1;
    background-color: #eee;
    padding: 10px;
  
    max-width: 500px;
  }
  */