@import "@aws-amplify/ui-react/styles.css";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

[data-amplify-authenticator][data-variation=modal] {
  overflow-y: auto;
  width: var(--amplify-components-authenticator-modal-width);
  height: var(--amplify-components-authenticator-modal-height);
  background-color: #fff;
  position: fixed;
  top: var(--amplify-components-authenticator-modal-top);
  left: var(--amplify-components-authenticator-modal-left);
  z-index: 1;
  border-radius: 40px 40px 40px 40px;
}

[data-amplify-authenticator] [data-amplify-router] {
  background-color: var(--amplify-components-authenticator-router-background-color);
  box-shadow: var(--amplify-components-authenticator-router-box-shadow);
  border-color: var(--amplify-components-authenticator-router-border-color);
  border-width: var(--amplify-components-authenticator-router-border-width);
  --amplify-components-authenticator-router-border-style: tan;
  /* border-style: var(--amplify-components-authenticator-router-border-style); */
}


/* Hide the title */
.amplify-heading--3 {
  font-size: var(--amplify-components-heading-5-font-size);
  font-weight: var(--amplify-components-heading-0-font-weight);
  display: none;
}

.amplify-tabs__item {
  position: relative;
  background-color: var(--amplify-components-tabs-item-background-color);
  --amplify-components-tabs-item-hover-color: hsl(0, 95%, 30%);
  box-sizing: border-box;
  color: hsl(16.15deg 39.72% 79.5%);
}

.amplify-tabs__item--active {
  color: hsl(347, 94%, 18%);
  border-color: hsl(0, 95%, 30%);
  background-color: var(--amplify-components-tabs-item-active-background-color);
  --amplify-components-tabs-item-hover-color: hsl(0, 95%, 30%);
  transition-property: none;
}

.amplify-button--primary {
  --amplify-components-button-primary-hover-background-color: #b83232;
  --amplify-internal-button-border-width: var(--amplify-components-button-primary-border-width);
  --amplify-internal-button-background-color: hsl(333.81deg 81.7% 25.64%);
  --amplify-internal-button-border-color: var(--amplify-components-button-primary-border-color);
  --amplify-internal-button-color: var(--amplify-components-button-primary-color);
  --amplify-internal-button-disabled-border-color: var(--amplify-components-button-primary-disabled-border-color);
  --amplify-internal-button-disabled-background-color: var(--amplify-components-button-primary-disabled-background-color);
  --amplify-internal-button-disabled-color: var(--amplify-components-button-primary-disabled-color);
  --amplify-internal-button-loading-background-color: var(--amplify-components-button-primary-loading-background-color);
  --amplify-internal-button-loading-border-color: var(--amplify-components-button-primary-loading-border-color);
  --amplify-internal-button-loading-color: var(--amplify-components-button-primary-loading-color);
}




/* :root {
  /* --amplify-fonts-default-variable: "Open Sans";
  --amplify-fonts-default-static: "Open Sans";
  --amplify-colors-brand-primary-10: var(--amplify-colors-blue-10);
  --amplify-colors-brand-primary-20: var(--amplify-colors-blue-20);
  --amplify-colors-brand-primary-40: var(--amplify-colors-blue-40);
  --amplify-colors-brand-primary-60: var(--amplify-colors-blue-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-blue-80);
  --amplify-colors-brand-primary-90: var(--amplify-colors-blue-90);
  --amplify-colors-brand-primary-100: var(--amplify-colors-blue-100);
  --amplify-colors-brand-secondary-10: var(--amplify-colors-neutral-10);
  --amplify-colors-brand-secondary-20: var(--amplify-colors-neutral-20);
  --amplify-colors-brand-secondary-40: var(--amplify-colors-neutral-40);
  --amplify-colors-brand-secondary-60: var(--amplify-colors-neutral-60);
  --amplify-colors-brand-secondary-80: var(--amplify-colors-neutral-80);
  --amplify-colors-brand-secondary-90: var(--amplify-colors-neutral-90);
  --amplify-colors-brand-secondary-100: var(--amplify-colors-neutral-100);
  --amplify-colors-radii-small: 0;
  --amplify-colors-radii-medium: 2px;
  --amplify-colors-radii-large: 4px;
  --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
  --amplify-colors-background-secondary: var(--amplify-colors-brand-primary-10);
  --amplify-components-tabs-item-border-color: var(--amplify-colors-neutral-60);

  --amplify-radii-small: 0;
  --amplify-radii-medium: 0;
  --amplify-radii-large: 0;
  --amplify-space-small: 1rem;
  --amplify-space-medium: 1.5rem;
  --amplify-space-large: 2rem;
  --amplify-border-widths-small: 2px;
  --amplify-border-widths-medium: 4px;
  --amplify-border-widths-large: 8px; */

/* }  */
