.diagrammenu {
    width: 100%;
    display: flex;
    /* justify-content: left; */
    align-items: center;
    font-size: 0.8rem;
    background-color: rgb(171, 169, 169);
    border-radius: 5px 0px 5px 0px;
    box-shadow: 2px 2px rgb(110, 123, 123);
}

  .leftmenuItems{
    width: 50%;
    display: flex;
    align-items: center;
    padding: 10px;
 
  }

  .diagrammenuLabel {
    font-size: 0.75rem;
    color: black;
  }

  .umlthemeSelect {
    padding: 5px 5px; 
    font-size: 0.75rem; /* Adjust font size */
    /* appearance:none; Remove default select arrow */
    background-color: rgb(239, 236, 236); /* Set background color */
    border: 0px solid #ccc; /* Add border */
    border-radius: 1px; /* Add border radius */
    cursor: pointer; /* Set cursor */
  }
  
  .umlthemeSelect:focus {
    outline: none; /* Remove focus outline */
  }
  
  .umlthemeSelect option {
    padding: 5px; /* Add padding to options */
  }
  
  .rightmenuItems{
    width: 50%;
    display: flex; /* Allow flex properties for child elements */
    justify-content: flex-end; /* Align child elements to the right */
  }