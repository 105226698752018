/* styles.css */
.message-box {
    max-width: 700px;
    margin: 50px auto;
    padding: 20px;
    border: 2px solid #e74c3c;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  
  .message-box h1 {
    color: #e74c3c;
  }
  
  .message-box p {
    color: #555;
    margin: 20px 0;
  }
  
  .message-box button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #e74c3c;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .message-box button:hover {
    background-color: #c0392b;
  }
  